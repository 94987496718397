<!--
  Component which displays the searches saved by the user
-->

<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-subheader class="mb-n2 overline">
            Niet opgeslagen
          </v-subheader>

          <template>
            <recent-search-item
              :dashboard="dashboard"
              key="x124"
              :search="recentSearch"
            />
          </template>

          <v-subheader
            class="mt-0 mb-n2 overline d-flex justify-space-between"
            v-if="savedSearches.data && savedSearches.data.length > 0"
          >
            <span>
              Opgeslagen {{ savedSearches.data.length }}/{{ maxNumberOfSearches }}
            </span>
            <v-spacer />
            <span v-if="dashboard === 'agenda'">
              <div class="mt-n2 mr-n4">
                <v-tooltip
                  bottom
                  open-delay="100"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                    >
                        <v-btn
                          @click="hasSavedSearchesWithCalendarEnabled
                            ? initSendWebCalActivationMail()
                            : null"
                          small
                          color=""
                        >
                          <v-icon
                            size="20"
                          >
                            mdi-calendar-sync
                          </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>
                      {{ hasSavedSearchesWithCalendarEnabled
                        ? 'Verstuur een agenda-synchronisatie activatiemail'
                        : `Vink eerst de agenda-synchronisatie aan voor
                        één of meer van je zoekopdrachten.` }}
                      </span>
                  </v-tooltip>
              </div>
            </span>
          </v-subheader>

          <template>
            <saved-search-item
              v-for="search in savedSearches.data"
              :dashboard="dashboard"
              :key="search.id"
              :search="search"
              :current="currentSearch"
              type="saved"
            />
          </template>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import SavedSearchItem from './SavedSearchItem.vue';
import RecentSearchItem from './RecentSearchItem.vue';

export default {
  name: 'SavedSearches',

  components: {
    SavedSearchItem,
    RecentSearchItem,
  },

  props: {
    dashboard: {
      type: String,
      required: true,
    },
  },

  computed: {
    currentSearch() { return this.$store.getters[this.currentSearchString]; },

    savedSearches() { return this.$store.getters[`${this.dashboard}AllSavedSearches`]; },

    recentSearches() { return this.$store.getters[`${this.dashboard}AllRecentSearches`]; },

    maxNumberOfSearches() { return this.$store.getters[`${this.dashboard}MaxNumberOfSearches`]; },

    recentSearch() {
      if (this.recentSearches && this.recentSearches.length > 0) {
        return this.recentSearches[0];
      }
      return this.currentSearch || {};
    },

    hasSavedSearchesWithCalendarEnabled() {
      if (this.dashboard === 'agenda') {
        if (this.savedSearches.data.some((savedSearch) => savedSearch.includeInWebcal === true)) {
          return true;
        }
      }
      return false;
    },
  },

  methods: {
    initSendWebCalActivationMail() {
      EventBus.$on('submitConfirmDialog', this.sendWebCalActationMail);
      this.$store.dispatch('setConfirmDialogHeader', 'Verstuur agenda-synchronisatie activatiemail');
      this.$store.dispatch('setConfirmDialogBody', 'Weet je zeker dat je een activatiemail wilt versturen?');
      this.$store.dispatch('setConfirmDialogVisible', true);
    },

    sendWebCalActationMail() {
      this.$store.dispatch('sendWebCalInviteMail').then(() => {
        this.$store.dispatch('setMessage', {
          message: 'Er is een activatiemail verstuurd',
          type: 'info',
        });
      });
      EventBus.$off('submitConfirmDialog');
    },
  },

};
</script>

<style scoped lang="scss">
</style>
