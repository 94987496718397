<!--
  Form used to save, add and edit searches
-->

<template>
  <div>
    <v-card
      color="#37474F"
      :height="$vuetify.breakpoint.lgAndDown ? 172 : 180"
      @click.self.stop="updateSearchParameters()"
    >
      <v-tooltip
        open-delay="500"
        left
        :disabled="$vuetify.breakpoint.mdAndDown"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            absolute
            class="mr-n2 mt-2 mb-10 fab-button"
            dark
            small
            fab
            bottom
            right
            color="teal lighten-1"
            @click.stop="save()"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Zoekopdracht opslaan</span>
      </v-tooltip>
      <v-card-text
        class="white--text"
        :class="$vuetify.breakpoint.lgAndDown ? 'pr-8' : 'pr-14'"
        @click.stop="updateSearchParameters()"
      >
        <nl-recent-search-item-detail
          v-if="dashboard === 'nl'"
          :search="search"
        />
        <nl-lr-recent-search-item-detail
          v-if="dashboard === 'nlLr'"
          :search="search"
        />
        <eu-recent-search-item-detail
          v-if="dashboard === 'eu'"
          :search="search"
        />
        <agenda-recent-search-item-detail
          v-if="dashboard === 'agenda'"
          :search="search"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { showDialog } from '@/helpers/dialogHelper';
import ServerConfig from '@/config.server';
import EventBus from '@/event-bus';

import NlRecentSearchItemDetail from '@/modules/documentDashboard/nlDashboard/components/NlRecentSearchItemDetail.vue';
import EuRecentSearchItemDetail from '@/modules/documentDashboard/euDashboard/components/EuRecentSearchItemDetail.vue';
import NlLrRecentSearchItemDetail from '@/modules/documentDashboard/nlLrDashboard/components/NlLrRecentSearchItemDetail.vue';
import AgendaRecentSearchItemDetail from '@/modules/agendaDashboard/components/AgendaRecentSearchItemDetail.vue';

import SavedSearchShareDialog from './SavedSearchShareDialog.vue';

export default {
  name: 'SavedSearchesItem',

  components: {
    NlRecentSearchItemDetail,
    EuRecentSearchItemDetail,
    NlLrRecentSearchItemDetail,
    AgendaRecentSearchItemDetail,
  },

  props: {
    dashboard: {
      type: String,
      required: true,
    },
    search: {
      type: Object,
      required: true,
    },
    current: {
      type: Object,
      required: false,
    },
    active: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      fab: null,
      busyRemove: false,
      busyUpdate: false,
      disabled: false,
    };
  },

  computed: {
    ...mapGetters({
      authHasModuleAccess: 'authHasModuleAccess',
      allSearchParameters: 'allSearchParameters',
    }),

    currentSearch() { return this.$store.getters[`${this.dashboard}CurrentSearch`]; },

    savedSearches() { return this.$store.getters[`${this.dashboard}AllSavedSearches`]; },

    maxNumberOfSearches() { return this.$store.getters[`${this.dashboard}MaxNumberOfSearches`]; },

    // TODO: See if we can do this more elegant without hardcoded query name
    agendaQuery() { return this.$store.getters.agendaQuery; },

    isActive() {
      return (
        this.search.id
        && this.currentSearch
        && this.currentSearch.id === this.search.id
      );
    },
  },

  methods: {
    openRssUrl() {
      if (this.authHasModuleAccess('MC15') && this.search.rssToken) {
        const apiUri = ServerConfig.address;
        window.open(`${apiUri}/rss/${this.search.rssToken}`, '_blank');
      }
    },

    showShareSavedSearchDialog() {
      const data = {
        search: this.search,
        dashboard: this.dashboard,
      };
      showDialog(SavedSearchShareDialog, data);
    },

    // Hides the right sidebar
    hideSidebarRight() {
      this.$store.dispatch('setSidebarVisible', {
        sidebar: 'right',
        visible: false,
      });
    },

    // This is a replacement for something old
    triggerEditMode() {
      this.$gtag.event('saved-search-toggle-edit-mode', {
        event_category: 'saved-search',
      });
    },

    checkQueryParams() {
      let bool = false;

      // Do nothing if there is no context
      if (this.dashboard === 'nl' && this.allSearchParameters.context.length === 0) {
        this.$store.dispatch('setMessage', {
          message: 'Selecteer een context',
          type: 'info',
        });
        bool = true;
      }

      // Do nothing if there is no query string
      if (
        (['nl', 'eu', 'nlLr'].includes(this.dashboard) && !this.allSearchParameters.query)
        || (this.dashboard === 'agenda' && !this.agendaQuery)
      ) {
        this.$store.dispatch('setMessage', {
          message: 'Geef een onderwerp op',
          type: 'info',
        });
        bool = true;
      }

      return bool;
    },

    // Shows save form
    save() {
      this.$gtag.event('saved-search-save', {
        event_category: 'saved-search',
      });

      if (this.savedSearches.data.length >= this.maxNumberOfSearches) {
        this.$store.dispatch('setMessage', {
          message: 'Maximale aantal zoekopdrachten bereikt',
          type: 'error',
        });
        return null;
      }

      if (this.checkQueryParams()) {
        this.fab = null;
        return null;
      }

      this.$store.dispatch(`${this.dashboard}SetDialogFormSavedSearchesVisible`, true);
      this.$store.dispatch(`${this.dashboard}SetCurrentSearch`, this.search);

      return null;
    },

    // Shows edit form
    edit() {
      this.$gtag.event('saved-search-edit', {
        event_category: 'saved-search',
      });

      if (this.checkQueryParams()) {
        this.fab = null;
        return null;
      }

      this.$store.dispatch(`${this.dashboard}SetDialogFormSavedSearchesVisible`, true);
      this.$store.dispatch(`${this.dashboard}SetCurrentSearch`, this.search);

      return null;
    },

    // Removes the current search
    // After being confirmed in a modal dialog
    removeInit() {
      this.$gtag.event('saved-search-remove-confirm', {
        event_category: 'saved-search',
      });

      EventBus.$on('submitConfirmDialog', this.removeHard);
      this.$store.dispatch('setConfirmDialogHeader', 'Zoekopdracht verwijderen');
      this.$store.dispatch('setConfirmDialogBody', 'Weet je zeker dat je deze zoekopdracht wilt verwijderen?');
      this.$store.dispatch('setConfirmDialogVisible', true);
    },

    removeHard() {
      this.$gtag.event('saved-search-remove', {
        event_category: 'saved-search',
      });

      this.busyRemove = true;
      this.disabled = true;
      this.$store.dispatch(`${this.dashboard}RemoveSearch`, this.search).then(
        () => {
          this.busyRemove = false;
          this.disabled = false;
        },
        () => { // on error
          this.busyRemove = false;
          this.disabled = false;
        },
      );
      // stop listening to events
      EventBus.$off('submitConfirmDialog');
    },

    // Updates the current search with the current search settings
    updateInit() {
      this.$gtag.event('saved-search-update-confirm', {
        event_category: 'saved-search',
      });

      if (this.checkQueryParams()) {
        this.fab = null;
        return null;
      }

      EventBus.$on('submitConfirmDialog', this.updateHard);
      this.$store.dispatch('setConfirmDialogHeader', 'Zoekopdracht overschrijven');
      this.$store.dispatch('setConfirmDialogBody', 'Weet je zeker dat je deze zoekopdracht wilt overschrijven?');
      this.$store.dispatch('setConfirmDialogVisible', true);

      return null;
    },

    updateHard() {
      this.$gtag.event('saved-search-update', {
        event_category: 'saved-search',
      });

      this.busyRemove = true;
      this.disabled = true;
      this.$store.dispatch(`${this.dashboard}SetCurrentSearch`, this.search);
      this.$store.dispatch(`${this.dashboard}UpdateSearch`, this.search).then(
        () => {
          this.busyUpdate = false;
          this.disabled = false;
        },
        () => { // on error
          this.busyUpdate = false;
          this.disabled = false;
        },
      );
      EventBus.$off('submitConfirmDialog');
    },

    // Sets the search parameters to the ones of the current item
    async updateSearchParameters() {
      this.$gtag.event('saved-search-update-search-parameters', {
        event_category: 'saved-search',
      });

      if (this.checkQueryParams()) {
        return null;
      }

      await this.$store.dispatch(`${this.dashboard}SetAllSearchParameters`,
        { ...this.search.queryDetails });
      await this.$store.dispatch(`${this.dashboard}SetCurrentSearch`, this.search);

      if (this.dashboard === 'agenda') {
        this.$store.dispatch('getAllAgendas');
        // This seems to be necessary otherwise the url doesn't update accordingly
        this.$router
          .push({
            name: 'agendaDashboard',
            query: {
              query: this.search.queryDetails.query,
            },
          })
          .catch(() => {});
      }

      if (this.dashboard === 'eu') {
        this.$router
          .push({
            name: 'euDashboard',
            query: {
              query: this.search.queryDetails.query,
              groupPaths: this.search.queryDetails.groupPaths.join(','),
              committees: this.search.queryDetails.euEpCommittees.join(',') || [],
            },
          })
          .catch(() => {});
      }

      if (this.dashboard === 'nl') {
        this.$router
          .push({
            name: 'nlDashboard',
            query: {
              query: this.search.queryDetails.query,
              context: this.search.queryDetails.context.join(','),
              groupPaths: this.search.queryDetails.groupPaths.join(','),
            },
          })
          .catch(() => {});
      }
      if (this.dashboard === 'nlLr') {
        this.$router
          .push({
            name: 'nlLrDashboard',
            query: {
              query: this.search.queryDetails.query,
              groupPaths: this.search.queryDetails.groupPaths.join(','),
            },
          })
          .catch(() => {});
      }

      return null;
    },
  },
};
</script>

<style lang="scss" scoped>

  .query {
    border-left: 5px solid transparent !important;
  }

  .query--active {
    border-left: 5px solid lighten(teal, 10%) !important;
  }

  .fab-button {
    z-index: 10000 !important;
  }
</style>
